.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
}

.bi {
    vertical-align: -.125em;
    fill: currentColor;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}


/*
* Globals
*/


/* Custom default button */

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
    color: #333;
    text-shadow: none;
    /* Prevent inheritance from `body` */
}


/*
* Base structure
*/

body {
    background-color: var(--bg-body);
    text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
}

body, html{
    scroll-behavior: smooth;
}

.cover-container {
    max-width: 42em;
}


/*
* Header
*/

.nav-masthead .nav-link {
    color: rgba(255, 255, 255, .5);
    border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
    border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link+.nav-link {
    margin-left: 1rem;
}

.nav-masthead .active {
    color: #fff;
    border-bottom-color: #fff;
}

.bg-blueDark {
   
    background-color: #1F2937;
}

.text-colorgradient{
    /*     background-color: rgba(33,37,41,var(--bs-bg-opacity,1)); */
    color: #040507;
}

.bg-greentimebox {
    background-color: #8FC73E !important;
}
.text-greentimebox {
    color: #8FC73E;
}

#startContent {
    height: 80vh;
}

.font-SourseSansPro {
    font-family: "SourseSansPro" !important;  
}

@font-face {
    font-family: "SourseSansPro";
    src: url("../fonts/SourceSansPro-Black.ttf");
}

.font-HandelGo {
    font-family: "HandelGo" !important;  
}

@font-face {
    font-family: "HandelGo";
    src: url("../fonts/HandelGo.ttf");
}

.font-interVar {
    font-family: "interV" !important;
    
}

@font-face {
    font-family: "interV";
    src: url("../fonts/Inter-V.ttf");
}

.text-colorTimeBox {
    color: #9AC455 !important;
}

.bg-top {
    background-image: url("../images/bg-top.png");
    background-size: contain;
}

.bg-platform {
    background-image: url("../images/bg-swirl.svg") !important;
    background-repeat: no-repeat;
}

.bg-buttonGreen{
    background-color:  #6a942b !important;
}

.bg-buttonGreen:hover{
    background-color: #8EC63F !important;
}

.width-img{
    width: 80% !important;
}

.transition{
    transition-delay: 0.5s;
}

.nav-bar{
    background-color: #1F2937;
    position: fixed !important;
    width: 100%;
    top: 0px;
    z-index: 11;
}

.bg-bluedark-footer{
    background-color: #111924;
}

.color-redesSociales {
    color: #0C0C14;
    text-shadow: none !important;
}

.border-green-timebox {
    border-color: #73923D !important;
}

.pricing-card{
    background-color: transparent;
}

.pricing-card:hover {
    background-color: #474F59 !important;
}

.pricing-card:hover a {
    color: #ffffff !important;
    background-color: #73923D;
}

.interceptor-section {
    background-color: #1F2937;
    background-image: url("../images/intersection-top.svg");
    height: 67px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

.navbar-dark {
    background-color: var( --bg-navbar-dark ) !important;
}

header{
    height: 55px;
}

.h-overview {
    height: 650px;
}

.bg-image-all-in-one {
    background-image: url('../images/bg-swirl.svg');
    background-repeat: no-repeat;
    background-position: left;
}

#features-tabs .active, .bg-features-mobile-section {
    background-color: #ffffff1a !important;
}

.feature-tab-mobile.active h4 {
    background-color: #6c757d !important;
}

.plan-list > li::marker {
    color: #9ac455;
}